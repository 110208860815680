import React from "react";
/* import Building from "../components/Building"; */
import AgeGate from "../components/AgeGate";

export default function IndexPage() {
  return (
     <div className="bgagegate">
        <AgeGate />
      </div> 
    
  );
}