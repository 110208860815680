import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import instagramlogo from "../../assets/images/icons/instagramlogo.png"
import twitterlogo from "../../assets/images/icons/twitterlogo.png"
import "../styles/Footer.css"

export default function Footer() {
    return (
        <footer className="d-none d-md-flex">
            <Container fluid>
                <Row>
                    <Col xs={4} md={3} className="d-flex justify-content-center mt-2">
                        <p className="separationterms ">BEBER CON MODERACIÓN Y EN CASA. <br/> PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. <br/> No compartir este contenido con menores.</p>
                    </Col>
                    <Col xs={6} md={6} className="d-flex justify-content-center mt-2">
                        <Row>
                        <a href={"../../public/docs/TerminosyCondiciones.pdf"} target="_blank"  rel="noreferrer" download><p className="separationterms ">TERMINOS Y CONDICIONES</p></a>
                        <a href={"../../public/docs/PoliticasdePrivacidad.pdf"} target="_blank"  rel="noreferrer" download><p className="separationterms">POLITICAS DE PRIVACIDAD</p></a>
                        <a href={"https://www.cerveceriaymalteriaquilmes.com/"} target="_blank"  rel="noreferrer"><p className="separationterms">INFORMACIÓN DE LA COMPAÑIA</p></a> 
                        </Row>
                    </Col>
                    <Col xs={2} md={3}>
                        <div className="text-center">
                            <Image src={instagramlogo} alt="logo instagram" className="mr-5" onClick={() => window.location.replace("https://www.instagram.com/blasfemia.arg/")}/>
                            <Image src={twitterlogo} alt="logo twitter" onClick={() => window.location.replace("https://twitter.com/argblasfemia?lang=esfirebase deploy")}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
