import React, { useEffect, useRef, useState } from "react";
import circleAgeGate from "../../assets/images/page_AgeGate/circleAgeGate.png";
import lineAgeGate from "../../assets/images/page_AgeGate/lineAgeGate.png";
import logoWhite from "../../assets/images/logos/logoWhite.png";
import { Card, Container, Row, Image } from "react-bootstrap";
import logo from "../../assets/images/logos/logoBlue.svg";
import Footer from "../components/Footer";
import '../styles/AgeGate.css';
import { Link } from "gatsby";

function _calculateAge(birthday) { // birthday is a date
  let ageDate = new Date(Date.now() - birthday.getTime()); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export default function AgeGate() {
  let [form, setForm] = useState({dd: '', mm:'', aaaa: ''}),
  [old, setOld] = useState(false),
  inputMonth = useRef(),
  inputDay = useRef(),
  inputYear = useRef();

  const onChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "dd") {
      if (value.length >= 2) {
        value = value.slice(0, 2);
        inputMonth.current.focus();
      }
    }
    else if (e.target.name === "mm") {
      if (value.length >= 2) {
        value = value.slice(0, 2);
        inputYear.current.focus();
      }
      else if (value.length === 0) {
        inputDay.current.focus();
      }
    }
    else {
      if (value.length > 4) {
        value = value.slice(0, 4);
      }
      else if (value.length === 0) {
        inputMonth.current.focus();
      }
    }    
    setForm({ ...form, [e.target.name]: value });
  }

  useEffect(() => {
    if (form.aaaa.length === 4 && form.dd && form.mm) {
      let birthday = new Date();
      birthday.setDate(form.dd)
      birthday.setMonth(parseInt(form.mm) - 1);
      birthday.setFullYear(form.aaaa);
      setOld(_calculateAge(birthday) >= 18);
    }
  }, [form]);

  return (
    <>
    <div className="heightindex">
      <div className="text-center pt-3 d-block d-md-none">
        <Image src={logoWhite} alt="page"/>
      </div>
      <div className="ptcard">
        <Container className="ptage d-flex justify-content-center">
          <Card className="text-center cardwidth">
            <Card.Body className="p-0">
              <Container fluid className="bgpink py-3">
                <Row>
                  <div>
                    <Image
                      src={circleAgeGate}
                      className="mt-2 ml-3 text-left circleAgeGate"
                      alt="logo"
                    />
                    <Image
                      src={circleAgeGate}
                      className="mt-2 ml-2 text-left circleAgeGate"
                      alt="logo"
                    />
                    <Image
                      src={circleAgeGate}
                      className="mt-2 ml-2 text-left circleAgeGate"
                      alt="logo"
                    />
                  </div>
                  <div xs={9} className="text-center pllogo">
                    <Image src={logo} alt="logo" className="blasfemialogo" />
                  </div>
                </Row>
              </Container>
              <Row className="flex-column">
                <h1 className="welcomeTitle">Bienvenidx!</h1>
                <h3 className="welcomeSubtitle">
                  Para continuar, ingresá tu fecha de nacimiento
                </h3>
                <form>
                  <Container fluid>
                    <Row className="welcomeContentInputs d-inline-flex">
                      <input
                        maxLength="2"
                        type="number"
                        name={"dd"}
                        onChange={onChange}
                        className="welcomeInput"
                        placeholder="DD"
                        value={form.dd}
                        ref={inputDay}
                      />
                      <Image src={lineAgeGate} className="separatorimage" alt="separator" />
                      <input
                        maxLength="2"
                        type="number"
                        name={"mm"}
                        onChange={onChange}
                        className="welcomeInput"
                        placeholder="MM"
                        value={form.mm}
                        ref={inputMonth}
                      />
                      <Image src={lineAgeGate} alt="separator" className="separatorimage" />
                      <input
                        maxLength="4"
                        type="number"
                        name={"aaaa"}
                        onChange={onChange}
                        className="welcomeInput"
                        placeholder="AAAA"
                        value={form.aaaa}
                        ref={inputYear}
                      />
                    </Row>
                  </Container>
                  <div className="mb-4 pb-2 mt-2">
                    <Link
                      to={old ? "/home" : "http://www.vivamosresponsablemente.com"}
                      className="welcomeButton"
                    >
                      aceptar
                    </Link>
                  </div>
                </form>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
      <div className="margintopfooter">
        <Footer />
      </div>
    </div>
    </>
  );
}